/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
    Input,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { thead, tbody, kundenliste, aufträgehead, kundenlistehead } from "variables/general";
import Modal from 'react-bootstrap/Modal'
import {withRouter} from 'react-router-dom';
import { compose } from "redux";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { IoIosRefresh, IoIosCheckmarkCircleOutline } from 'react-icons/io';
class TableList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
        showModal: false,
        companys: [{total: 0}],
        all: [],
        name: "",
        street: "",
        city: "",
        plz: "",
        vat: "",
        service: "",
        loading: false
      }
  }
  componentDidMount () {
    this.fetch_feed()
  }
  fetch_feed = async () => {
    this.setState({loading: true})
    setTimeout(() => {
      this.props.client
        .query({
          query: gql`
            query {
              companys {
                id
                name
                street
                city
                plz
                service
                orders {
                  companyname
                  companycity
                  status
                  total
                  ordernumber
                }
                total
                vat
              }
            }
          `
          ,
          fetchPolicy: "no-cache",
        })
        .then(async data => {
          console.log("Fetch Feed: ", data.data.companys);
          for (var i = 0; i < data.data.companys.length; i++) {
            console.log(i)
            this.getDetails(data.data.companys[i].id)
          }
          this.setState({loading: false, companys: data.data.companys });
        })
        .catch(async err => {
          console.log("Err: ", err);
        });
    }, 500)
  };
  getDetails = (id) => {
    this.props.client
      .query({
        query: gql`
          query ($id: ID!){
            getOrdersById(id: $id) {
              id
              company
              ordernumber
              companyname
              companycity
              status
              total
              chickenbody
              Vealbody
              Breadbody
              batchnumber
              invoice
              vat
              totalexclude
            }
          }
        `,
        fetchPolicy: "no-cache",
        variables: {
                id: id
              }
      })
      .then(async data => {
        console.log('Orders: ', data)
        let count = 0;
        for (var i = 0; i < data.data.getOrdersById.length; i++) {
          count = count + parseFloat(data.data.getOrdersById[i].total)
        }
        this.setState(prevState => ({
          all: [...prevState.all, count]
        }))
      })
  }
  close = () => {
    this.setState({ showModal: false });
  }

  open = () => {
    this.setState({ showModal: true });
  }
  nextPath = (path, company) => {
    this.props.history.push({
      pathname: path,
      state: { detail: company }
    });
  }
  addNewCompany = () => {
    let data = {
      name: this.state.name,
      street: this.state.street,
      city: this.state.city,
      plz: this.state.plz,
      vat: this.state.vat,
      service: this.state.service
    }
    let data2 = {
      name: this.state.name,
      street: this.state.street,
      city: this.state.city,
      plz: this.state.plz,
      vat: this.state.vat,
      service: this.state.service,
      total: 0
    }
    this.props.addCompany({  variables: {
          auth: data
        }})
      .then(async data => {
        console.log("Fetch Feed: ", data);
        this.setState({ showModal: false, companys: [...this.state.companys, data2]});
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  }
  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Neuen Kunden hinzufügen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <label>Name</label>
              <Input
                onChange={(name) => {
                    this.setState({name: name.target.value})}}
                value={this.state.name}
                placeholder="Name"
                type="text"
              />
              <br />
              <label>Straße</label>
              <Input
              onChange={(street) => {
                  this.setState({street: street.target.value})}}
                placeholder="Straße"
                type="text"
              />
              <br />
              <label>Stadt</label>
              <Input
              onChange={(city) => {
                  this.setState({city: city.target.value})}}
                placeholder="Stadt"
                type="text"
              />
              <br />
              <label>PLZ</label>
              <Input
              onChange={(plz) => {
                  this.setState({plz: plz.target.value})}}
                placeholder="PLZ"
                type="text"
              />
              <br />
              <label>VAT Number</label>
              <Input
              onChange={(vat) => {
                  this.setState({vat: vat.target.value})}}
                placeholder="VAT Number"
                type="text"
              />
              <br />
              <label>Service Charge in Percent</label>
              <Input
              onChange={(vat) => {
                  this.setState({service: vat.target.value.slice(0, -1) })}}
                  value={this.state.service +  "%"}
                placeholder="Service Charge in Percent"
                type="text"
              />
            </FormGroup>


            <hr />

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.addNewCompany()} color="primary">Hinzufügen</Button>
          </Modal.Footer>
        </Modal>
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                <CardTitle tag="h4">
                  <Row>
                    <Col sm="5">
                      Kundenliste
                      <Fab onClick={() =>this.open()} style={{left: "2%"}} color="primary" size="small" aria-label="add" >
                        <AddIcon size="small"/>
                      </Fab>
                    </Col>
                    <Col style={{textAlign: 'center'}} sm="4">
                      <text style={this.state.loading ? {color: 'grey', fontSize: 18}: {display: 'none'}}> Loading... </text>
                    </Col>
                    <Col sm="2">
                    <Button style={{float: 'right', marginRight: "3%",}} onClick={() => {  this.fetch_feed()}} color="link" > <IoIosRefresh /> </Button>

                    </Col>

                    </Row>
                  </CardTitle>


                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        {kundenlistehead.map((prop, key) => {
                          if (key === thead.length - 1)
                            return (
                              <th key={key} className="text-right">
                                {prop}
                              </th>
                            );
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.companys.map((topprop, key) => {
                        return (
                          <tr key={key}>
                                <td key={key}>{topprop.name}</td>
                                <td key={key}>{topprop.city}</td>
                                <td key={key}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.all[key])}</td>

                                 <td key={key} className="text-right">
                                 <Button onClick={() => this.nextPath("/admin/details/" + encodeURI(topprop.id), topprop)} color="link">Bearbeiten</Button>
                                 </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const addCompany = gql`
mutation addCompany($auth: CompanyInput!) {
  addCompany(auth: $auth) {
    token
  }
}
`;

export default  compose(graphql(addCompany, { name: "addCompany" }), withRouter, withApollo)(TableList);
