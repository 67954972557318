/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {HashRouter, Router, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "assets/scss/now-ui-dashboard.scss?v1.2.0";
import "assets/css/demo.css";
import SignIn from "views/Signup.jsx"
import AdminLayout from "layouts/Admin.jsx";
import UserPageLayout from "layouts/Mask.jsx";
import RegularTablesLayout from "layouts/AufträgeLayout.jsx";
import TableListLayout from "layouts/TableListLayout.jsx";
import ProductListLayout from "layouts/ProductListLayout.jsx";
import ViewUserPageLayout from "layouts/ViewMask.jsx";
import ViewCompanyPageLayout from "layouts/ViewCompanyPageLayout.jsx";
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import Dashboard from "views/Dashboard.jsx";
import Notifications from "views/Notifications.jsx";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/TableList.jsx";
import Aufträge from "views/Aufträge.jsx";
import Produktliste from "views/Produktliste.jsx";
import Maps from "views/Maps.jsx";
import Upgrade from "views/Upgrade.jsx";
import UserPage from "views/UserPage.jsx";
import App from "./App"
import Details from "views/details.jsx";
import { InMemoryCache } from 'apollo-cache-inmemory';
import routes from "routes.js";
const hist = createBrowserHistory();
var sessionstorage = require('sessionstorage');

const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }

const client = new ApolloClient({
    uri: 'https://api.docs-kbkint.com/graphql',
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,

});
const token = sessionstorage.getItem('token')
ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={hist}>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);
