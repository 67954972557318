/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState }  from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
    Input,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { thead, tbody, produktlisteBody, produktliste } from "variables/general";
import Modal from 'react-bootstrap/Modal'
import ModalContent from "./Modal"
class Productlist extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
        showModal: false
      }
  }

  getInitialState() {
      return { showModal: false };
    }

    close = () => {
      this.setState({ showModal: false });
    }

    open = () => {
      this.setState({ showModal: true });
    }
  render() {

    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Produkt hinzufügen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <label>Name</label>
              <Input
                placeholder="Name"
                type="text"
              />
              <label>Preis pro Kg</label>
              <Input
                placeholder="Preis pro Kg"
                type="text"
              />
            </FormGroup>


            <hr />

          </Modal.Body>
          <Modal.Footer>
            <Button color="primary">Hinzufügen</Button>
          </Modal.Footer>
        </Modal>
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                      Produktliste
                  </CardTitle>

                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        {produktliste.map((prop, key) => {

                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {produktlisteBody.map((prop, key) => {
                        return (
                          <tr key={key}>
                            {prop.data.map((prop, key) => {
                              if (key === produktlisteBody.length)
                                return (
                                  <td key={key} className="text-right">

                                  </td>
                                );
                                if (key === 2)
                                  return (
                                    <td key={key}>
                                      {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}
                                    </td>
                                  );

                              return <td key={key}>{prop}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Productlist;
