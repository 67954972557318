/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Table,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import {
  useParams
} from "react-router-dom";
import { thead, tbody, aufträge, aufträgehead } from "variables/general";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import {withRouter} from 'react-router-dom';
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import { compose } from "redux";
import { IoIosRefresh,IoIosClose, IoMdCreate,IoIosCheckmarkCircleOutline } from 'react-icons/io';
import Modal from 'react-bootstrap/Modal'
class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      edit: false,
      company: [],
      orders: [],
      ordersSend: [],
      ordersDone: [],
      delay: 0,
      all: 0,
      name: this.props.location.state.detail.name,
      street: this.props.location.state.detail.street,
      plz: this.props.location.state.detail.plz,
      city: this.props.location.state.detail.city,
      service: this.props.location.state.detail.service,
      vat: this.props.location.state.detail.vat,
      nameBackup: this.props.location.state.detail.name,
      streetBackup: this.props.location.state.detail.street,
      plzBackup: this.props.location.state.detail.plz,
      cityBackup: this.props.location.state.detail.city,
      serviceBackup: this.props.location.state.detail.service,
      vatBackup: this.props.location.state.detail.vat,
    }
  }
  componentDidMount () {
    this.getDetails()
  }
  nextPath = (path, company) => {
    this.props.history.push({
      pathname: path,
      state: { detail: company }
    });
  }
  updateCompany = () => {
    let data = {
      name: this.state.name,
      street: this.state.street,
      city: this.state.city,
      plz: this.state.plz,
      vat: this.state.vat,
      service: this.state.service
    }
    let data2 = {
      showModal: false,
      edit: false,
      nameBackup: this.state.name,
      streetBackup: this.state.street,
      cityBackup: this.state.city,
      plzBackup: this.state.plz,
      vatBackup: this.state.vat,
      serviceBackup: this.state.service
    }
    this.props.updateCompany({  variables: {
          id: this.props.location.state.detail.id,
          auth: data
        }})
      .then(async data => {
        console.log("Fetch Feed: ", data);
        this.setState(data2);
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  }
  deleteCompany = () => {
    this.props.deleteCompany({  variables: {
          id: this.props.location.state.detail.id
        }})
      .then(async data => {
        console.log("Fetch Feed: ", data);
        window.location.href="https://docs-kbkint.com/notifications"
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  }
  getDetails = () => {
    this.props.client
      .query({
        query: gql`
          query ($id: ID!){
            getOrdersById(id: $id) {
              id
              company
              ordernumber
              companyname
              companycity
              status
              total
              chickenbody
              Vealbody
              Breadbody
              batchnumber
              invoice
              vat
              totalexclude
            }
          }
        `,
        fetchPolicy: "no-cache",
        variables: {
                id: this.props.location.state.detail.id
              }
      })
      .then(async data => {
        console.log('Orders: ', data)
        let count = 0;
        let all = 0;
        for (var i = 0; i < data.data.getOrdersById.length; i++) {
          if (parseInt(data.data.getOrdersById[i].status) === 0 || parseInt(data.data.getOrdersById[i].status) === 1) {
            count = count + parseFloat(data.data.getOrdersById[i].total)
          }

          all = all + parseFloat(data.data.getOrdersById[i].total)
        }
        this.setState({delay: count, all: all, loading: false, orders: data.data.getOrdersById.filter((el) => {
            return parseInt(el.status) ===  0
          }),
          ordersSend: data.data.getOrdersById.filter((el) => {
              return parseInt(el.status) ===  1
            }),
          ordersDone: data.data.getOrdersById.filter((el) => {
              return parseInt(el.status) ===  2
            }) });
      })
  }
  render() {
    const { detail } = this.props.location.state
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content" style={{alignItems: 'center'}}>
        <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false})}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.nameBackup}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 style={{textAlign: 'center'}}>Wollen Sie {this.state.nameBackup} wirklich löschen?</h4>

          </Modal.Body>
          <Modal.Footer>
            <Button color="secondary" onClick={() => this.setState({showModal: false})}>Schließen</Button>
            <Button color="danger" onClick={() => this.deleteCompany()}>Löschen</Button>
          </Modal.Footer>
        </Modal>
          <Row className="justify-content-md-center">
            <Col md="10">
              <Card>
                <CardHeader>
                <Row>
                  <Col sm={"8"}>
                  <h1 style={this.state.edit ? {display: 'none'} : {}} className="title">{this.state.nameBackup}</h1>
                  <Input
                   style={this.state.edit ? {width: "65%", fontSize: "1.7em", fontWeight: 'bold'} : {display: 'none'}}
                    onChange={(name) => {
                        this.setState({name: name.target.value})}}
                    value={this.state.name}
                    placeholder="Name"
                    type="text"
                  />
                  </Col>

                  <Col sm={"4"}  >
                    < Button style={{float: 'flex-start'}} color="danger" onClick={() => this.setState({showModal: true})}>Löschen</Button>
                    <Button style={{float: 'flex-end'}}  onClick={() => { this.setState({edit: !this.state.edit})}} color="secondary">
                      <IoMdCreate size="1.5em" style={this.state.edit ? {display: 'none'} : {}} />
                      <IoIosClose size="1.5em" style={this.state.edit ? {} : {display: 'none'}} />
                    </Button>
                    < Button  style={this.state.edit ? {} : {display: 'none'}} color="primary" onClick={() => this.updateCompany()}>Speichern</Button>

                  </Col>

                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                    <Table responsive>
                      <thead>
                        <tr>

                          <th>Anschrift</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>
                          <td>Straße: {this.state.edit ?
                            <Input

                              onChange={(name) => {
                                  this.setState({street: name.target.value})}}
                              value={this.state.street}
                              placeholder="Name"
                              type="text"
                            /> :
                          this.state.streetBackup}</td>
                        </tr>
                        <tr>
                          <td>PLZ: {this.state.edit ?
                            <Input

                              onChange={(name) => {
                                  this.setState({plz: name.target.value})}}
                              value={this.state.plz}
                              placeholder="Name"
                              type="text"
                            /> :
                          this.state.plzBackup}</td>
                        </tr>
                        <tr>
                          <td>Stadt: {this.state.edit ?
                            <Input

                              onChange={(name) => {
                                  this.setState({city: name.target.value})}}
                              value={this.state.city}
                              placeholder="Name"
                              type="text"
                            /> :
                            this.state.cityBackup }</td>
                        </tr>
                        <tr>
                          <td>VAT: {this.state.edit ?
                            <Input

                              onChange={(name) => {
                                  this.setState({vat: name.target.value})}}
                              value={this.state.vat}
                              placeholder="Name"
                              type="text"
                            /> :
                          this.state.vatBackup}</td>
                        </tr>
                        <tr>
                          <td>Service: {this.state.edit ?
                            <Input
                               onChange={(vat) => {
                                   this.setState({service: vat.target.value.slice(0, -1) })}}
                                value={this.state.service +  "%"}
                                placeholder="Service Charge in Percent"
                                type="text"
                               />:
                          this.state.serviceBackup+  "%"}</td>
                        </tr>
                      </tbody>
                    </Table>
                    </Col>
                    <Col md="6">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th >Umsatz</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Gesamtumsatz</td>

                          <td className="text-center">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.all)}</td>
                        </tr>
                        <tr>
                          <td>Offen</td>
                          <td className="text-center">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.delay)}</td>
                        </tr>
                      </tbody>
                    </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <Table responsive>
                    <thead>
                      <tr>

                        <th>Offene Aufträge</th>
                      </tr>
                    </thead>
                      <thead className="text-primary">
                        <tr>
                          {aufträgehead.map((prop, key) => {
                            if (key === thead.length)
                              return (
                                <th key={key} className="text-right">
                                  {prop}
                                </th>
                              );
                            return <th  key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.orders.map((prop, key) => {
                          return (
                            <tr key={key}>
                                <td key={key}>{prop.ordernumber}</td>
                                <td key={key}>{prop.companyname}</td>
                                <td key={key}>{prop.companycity}</td>
                                <td key={key}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop.total)}</td>
                                <td key={key} className="text-right">
                                <Button color="link" onClick={() => this.nextPath("/admin/orderdetails/" + encodeURI(prop.ordernumber), prop)}>Bearbeiten</Button>
                                </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    </Col>
                    <Col md="12">
                    <Table responsive>
                      <thead>
                        <tr>

                          <th>Versendetet Aufträge</th>
                        </tr>
                      </thead>
                      <thead className="text-primary">
                        <tr>
                          {aufträgehead.map((prop, key) => {
                            if (key === thead.length)
                              return (
                                <th key={key} className="text-right">
                                  {prop}
                                </th>
                              );
                            return <th  key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ordersSend.map((prop, key) => {
                          return (
                            <tr key={key}>
                                <td key={key}>{prop.ordernumber}</td>
                                <td key={key}>{prop.companyname}</td>
                                <td key={key}>{prop.companycity}</td>
                                <td key={key}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop.total)}</td>
                                <td key={key} className="text-right">
                                <Button color="link" onClick={() => this.nextPath("/admin/orderdetails/" + encodeURI(prop.ordernumber), prop)}>Bearbeiten</Button>
                                </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    </Col>
                    <Col md="12">
                    <Table responsive>
                    <thead>
                      <tr>

                        <th>Abgeschlossene Aufträge</th>
                      </tr>
                    </thead>
                      <thead className="text-primary">
                        <tr>
                          {aufträgehead.map((prop, key) => {
                            if (key === thead.length)
                              return (
                                <th key={key} className="text-right">
                                  {prop}
                                </th>
                              );
                            return <th  key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ordersDone.map((prop, key) => {
                          return (
                            <tr key={key}>
                                <td key={key}>{prop.ordernumber}</td>
                                <td key={key}>{prop.companyname}</td>
                                <td key={key}>{prop.companycity}</td>
                                <td key={key}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop.total)}</td>
                                <td key={key} className="text-right">
                                <Button color="link" onClick={() => this.nextPath("/admin/orderdetails/" + encodeURI(prop.ordernumber), prop)}>Bearbeiten</Button>
                                </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const updateCompany = gql`
mutation updateCompany($id: ID!, $auth: CompanyInput!) {
  updateCompany(id: $id, auth: $auth) {
    token
  }
}
`;
const deleteCompany = gql`
mutation deleteCompany($id: ID!) {
  deleteCompany(id: $id) {
    token
  }
}
`;
export default compose(graphql(deleteCompany, { name: "deleteCompany" }),graphql(updateCompany, { name: "updateCompany" }), withRouter, withApollo)(Details);
