/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody, aufträge, aufträgehead } from "variables/general";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "redux";
import {withRouter} from 'react-router-dom';
class Archiv extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
        orders: [],
        ordersDone: []
      }
  }
  componentDidMount () {
    //this.fetch_feed()
  }
  nextPath = (path, company) => {
    this.props.history.push({
      pathname: path,
      state: { detail: company }
    });
  }
  fetch_feed = async () => {
    this.props.client
      .query({
        query: gql`
          query {
            savedOrders {
              id
              ordernumber
              companyname
              companycity
              status
              total
              chickenbody
              Vealbody
              Breadbody
              batchnumber
              invoice
              totalexclude
              vat
            }
          }
        `,
        fetchPolicy: "no-cache"
      })
      .then(async data => {
        console.log("Fetch Feed: ", data.data.savedOrders);
        this.setState({ orders: data.data.savedOrders.filter((el) => {
            return parseInt(el.status) ===  0
          }),
          ordersDone: data.data.savedOrders.filter((el) => {
              return parseInt(el.status) ===  1
            }) });
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };
  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Archivierte Aufträge</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        {aufträgehead.map((prop, key) => {
                          if (key === thead.length)
                            return (
                              <th key={key} className="text-right">
                                {prop}
                              </th>
                            );
                          return <th  key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders.map((prop, key) => {
                        return (
                          <tr key={key}>
                              <td key={key}>{prop.ordernumber}</td>
                              <td key={key}>{prop.companyname}</td>
                              <td key={key}>{prop.companycity}</td>
                              <td key={key}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop.total)}</td>
                              <td key={key} className="text-right">
                              <Button color="link" onClick={() => this.nextPath("/admin/orderdetails/" + encodeURI(prop.ordernumber), prop)}>Bearbeiten</Button>
                              </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}


export default  compose( withRouter, withApollo)(Archiv);
