/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  CardTitle,
  Table,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { theadCreate, tbody, breadBody, chickenbody } from "variables/general";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "redux";
import Modal from 'react-bootstrap/Modal'
import ReactLoading from "react-loading";
class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: "",
      batchnumber: "",
      ordernumber: "",
      data: tbody,
      dataChicken: chickenbody,
      databread: breadBody,
      companys: [],
      all: [],
      selectedCompany: [{id: "", name: "", street: "", city: "", plz: "", vat: ""}],
      total: 0,
      vat: 0,
      totalexclude: 0,
      dropdownOpen: false,
      loading: false
    }
  };
  componentDidMount () {
    this.fetch_feed()
  }
  fetch_feed = async () => {
    this.props.client
      .query({
        query: gql`
          query {
            companys {
              id
              name
              street
              city
              plz
              vat
              service
            }
          }
        `,
        fetchPolicy: "no-cache"
      })
      .then(async data => {
        console.log("Fetch Feed: ", data.data.companys);

        this.setState({ companys: data.data.companys });

      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };

  updateValue = (value, data, key) => {
    let kg = value * parseFloat(data[key].kg)
    console.log(kg)
    let result = kg * parseFloat(data[key].data[2])
    console.log(result)
    data[key].data[0] = value
    data[key].data[1] = ""+kg.toLocaleString('de-DE') + "kg"
    data[key].data[3] = result
    let total = this.totalveal() + this.totalchicken() + this.totalbread()
    let vat = total * parseFloat("0.0" + this.state.selectedCompany[0].service);
    let totalexclude = total + vat;
    this.setState({data: data, total: total, vat: vat, totalexclude: totalexclude})
  }

  totalveal = () => {
    let total = 0;
    for (var i = 0; i < this.state.data.length; i++) {
      total = total + parseFloat(this.state.data[i].data[3])
    }
    return total
  }
  totalchicken = () => {
    let total = 0;
    for (var i = 0; i < this.state.dataChicken.length; i++) {
      total = total + parseFloat(this.state.dataChicken[i].data[3])
    }
    return total
  }
  totalbread = () => {
    let total = 0;
    for (var i = 0; i < this.state.databread.length; i++) {
      total = total + parseFloat(this.state.databread[i].data[3])
    }
    return total
  }
  updateChickenValue = (value, data, key) => {
    let kg = value * parseFloat(data[key].kg)
    console.log(kg)
    let result = kg * parseFloat(data[key].data[2])
    console.log(result)
    data[key].data[0] = value
    data[key].data[1] = ""+kg.toLocaleString('de-DE') + "kg"
    data[key].data[3] = result
    let total = this.totalveal() + this.totalchicken() + this.totalbread()
    let vat = total * parseFloat("0.0" + this.state.selectedCompany[0].service);
    let totalexclude = total + vat;
    this.setState({dataChicken: data,
      total: total,
      vat: vat,
      totalexclude: totalexclude
    })
  }
  updateBreadValue = (value, data, key) => {
    let kg = value * parseFloat(data[key].data[2])
    console.log(kg)
    data[key].data[1] = value;
    data[key].data[3] = kg;
    let total = this.totalveal() + this.totalchicken() + this.totalbread()
    let vat = total * parseFloat("0.0" + this.state.selectedCompany[0].service);
    let totalexclude = total + vat;
    this.setState({databread: data, total: total
    , vat: vat
    , totalexclude: totalexclude})
  }
  toggle = () => {
    this.setState({dropdownOpen: !this.state.dropdownOpen})
  }
  create = async () => {
    this.setState({loading: true})
    let data = {
      date: this.state.orderdate,
      store: false,
      createdAt: new Date().toLocaleDateString(),
      company: this.state.selectedCompany[0].id,
      companyname: this.state.selectedCompany[0].name,
      companycity: this.state.selectedCompany[0].city,
      status: "0",
      invoice: this.state.invoice,
      batchnumber: this.state.batchnumber,
      ordernumber: this.state.ordernumber,
      chickenbody: [JSON.stringify(this.state.dataChicken)],
      Vealbody: [JSON.stringify(this.state.data)],
      Breadbody: [JSON.stringify(this.state.databread)],
      total: this.state.total,
      vat: this.state.vat,
      totalexclude: this.state.totalexclude
    }
    console.log(data)
    this.props.createPdf({  variables: {
          data: data
        }})
      .then(async data => {
        console.log("Fetch: ", data);
        setTimeout(() => {
          this.setState({loading: false})
          setTimeout(() => {
            window.open("https://api.docs-kbkint.com/download/" + data.data.createPdf.token, "_blank")
          }, 1000)
        }, 2000)
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };
  saveOrder = async () => {
    this.setState({loading: true})
    let data = {
      date: this.state.orderdate,
      store: true,
      createdAt: new Date().toLocaleDateString(),
      company: this.state.selectedCompany[0].id,
      companyname: this.state.selectedCompany[0].name,
      companycity: this.state.selectedCompany[0].city,
      status: "0",
      invoice: this.state.invoice,
      batchnumber: this.state.batchnumber,
      ordernumber: this.state.ordernumber,
      chickenbody: [JSON.stringify(this.state.dataChicken)],
      Vealbody: [JSON.stringify(this.state.data)],
      Breadbody: [JSON.stringify(this.state.databread)],
      total: this.state.total,
      vat: this.state.vat,
      totalexclude: this.state.totalexclude
    }
    console.log(data)
    this.props.savePdf({  variables: {
          data: data
        }})
      .then(async data => {
        console.log("Fetch: ", data);
        setTimeout(() => {
          this.setState({loading: false})
        }, 3500)
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };
  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
        <Modal show={this.state.loading} onHide={this.close}       aria-labelledby="contained-modal-title-vcenter"
      centered>
          <Modal.Header id="contained-modal-title-vcenter">
            <Modal.Title style={{margin: 'auto'}}>PDF's werden erstellt</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{margin: 'auto'}}>
          <div style={{margin: 'auto'}}>
            <ReactLoading  type={"spinningBubbles"} color="black"  height={50} width={50} />
          </div>
          </Modal.Body>
        </Modal>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Maske</h5>
                </CardHeader>
                <CardBody>
                <Row>
                  <Col md="6">
                    <Form>
                      <Row>
                        <Dropdown style={{"background-color": '#FFFFFF'}} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                          <DropdownToggle caret>
                            Unternehmen wählen
                            </DropdownToggle>
                          <DropdownMenu>
                          {this.state.companys.map((prop, key) => {
                            return <DropdownItem onClick={() => {this.setState({ selectedCompany: [prop]});console.log(prop)}} key={key}>{prop.name}</DropdownItem>;
                          })}


                          </DropdownMenu>
                        </Dropdown>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Invoice Nummer</label>
                            <Input
                            onChange={(name) => {
                                this.setState({invoice: name.target.value})}}
                              placeholder="Invoice Nummer"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Batch Nummer</label>
                            <Input
                            onChange={(name) => {
                                this.setState({batchnumber: name.target.value})}}
                              placeholder="Batch Nummer"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Order Nummer</label>
                            <Input
                            onChange={(name) => {
                                this.setState({ordernumber: name.target.value})}}
                              placeholder="Order Nummer"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Order Date</label>
                            <Input
                              onChange={(name) => {
                                this.setState({orderdate: name.target.value})}}

                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col md="6">
                  {this.state.selectedCompany[0].name ?
                     (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>{this.state.selectedCompany[0].name}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{this.state.selectedCompany[0].street}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{this.state.selectedCompany[0].plz}, {this.state.selectedCompany[0].city}</td>

                          </tr>
                          <tr>
                            <td>VAT: {this.state.selectedCompany[0].vat}</td>

                          </tr>
                        </tbody>
                      </Table>
                    ):(<div> </div>)
                  }

                  </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        {theadCreate.map((prop, key) => {
                          if (key === theadCreate.length - 1)
                            return (
                              <th key={key} className="text-right">
                                {prop}
                              </th>
                            );
                            if (key === theadCreate.length - 2)
                              return (
                                <th key={key} className="text-right">
                                  {prop}
                                </th>
                              );
                              if (key === theadCreate.length - 3)
                                return (
                                  <th key={key} className="text-right">
                                    {prop}
                                  </th>
                                );
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <thead className="text-primary">
                    <tr>
                      <th key={"Kebab"}>
                        Veal Kebab
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((prop, key) => {
                        return (
                          <tr key={key}>
                            <td key={key} className="text-right">
                              <Row>
                                <Col className="pr-1" md="6">
                                <Input
                                  pattern="[0-9]*"
                                  type='number'
                                  onChange={(e) => this.updateValue(`${e.target.value}`, this.state.data, key)}
                                  placeholder="0"

                                />
                                </Col>
                                <Col className="pr-1" md="3">
                                    X
                                </Col>
                                <Col className="pr-1" md="3">
                                    {prop.kg}kg
                                </Col>
                              </Row>
                            </td>
                            {prop.data.map((prop, key) => {
                              if (key === 0) {

                              } else {
                                if (key === theadCreate.length - 1|| key === theadCreate.length)
                                  return (
                                    <td key={key} className="text-right" >
                                    <strong>  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}</strong>
                                    </td>
                                  );
                                  if (key === theadCreate.length - 2)
                                    return (
                                      <td key={key} className="text-right">
                                       {
                                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)
                                      }
                                      </td>
                                    );
                                return <td key={key} className="text-right">{prop}</td>;
                              }

                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <thead className="text-primary">
                    <tr>
                      <th key={"Kebab"}>
                        Chicken Kebab
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataChicken.map((prop, key) => {
                        return (
                          <tr key={key}>
                          <td key={key} className="text-right">
                            <Row>
                              <Col className="pr-1" md="6">
                              <Input
                                type='number'
                                onChange={(e) => this.updateChickenValue(`${e.target.value}`, this.state.dataChicken, key)}
                                placeholder="0"
                              />
                              </Col>
                              <Col className="pr-1" md="3">
                                  X
                              </Col>
                              <Col className="pr-1" md="3">
                                  {prop.kg}kg
                              </Col>
                            </Row>
                            </td>
                            {prop.data.map((prop, key) => {
                              if (key === 0) {

                              } else {
                                  if (key === theadCreate.length - 1 || key === theadCreate.length)
                                    return (
                                      <td key={key} className="text-right">
                                      <strong>  {
                                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}</strong>
                                      </td>
                                    );
                                  if (key === theadCreate.length - 2)
                                    return (
                                      <td key={key} className="text-right">
                                       {
                                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}
                                      </td>
                                    );
                                  return <td key={key} className="text-right">{prop}</td>;
                            }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <br/>
                    <br/>
                    <tbody>
                      {this.state.databread.map((prop, keyz) => {
                        return (
                          <tr key={keyz}>
                            {prop.data.map((prop, key) => {
                              if (key === 0)
                                return (
                                  <td key={key}>
                                    {prop}
                                  </td>
                                );
                                if (key === 1)
                                  return (
                                    <td key={key} className="text-right">
                                      <Row>
                                        <Col className="pr-1" md="6">
                                        <Input
                                          type='number'
                                          onChange={(e) => this.updateBreadValue(`${e.target.value}`, this.state.databread, keyz)}
                                          placeholder="0"
                                        />
                                        </Col>
                                        <Col className="pr-1" md="3">
                                            Boxes
                                        </Col>
                                        <Col className="pr-1" md="3">

                                        </Col>
                                      </Row>
                                      </td>
                                  );
                                  if (key === 3){
                                      return (<td key={key} className="text-right"><strong>{
                                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}</strong></td>)} else {

                                        return (<td key={key} className="text-right">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}</td>)
                                  }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <tbody>
                          <tr key={0}>
                               <td key={1} className="text-right"><strong>Total amount</strong></td>
                               <td key={2} className="text-right"></td>
                               <td key={3} className="text-right"></td>
                               <td key={4} className="text-right"><strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.total)}</strong></td>
                          </tr>
                          <tr key={0}>
                               <td key={1} className="text-right">{this.state.selectedCompany[0].service}% Service charge</td>
                               <td key={2} className="text-right"></td>
                               <td key={3} className="text-right"></td>
                               <td key={4} className="text-right">{
                                 new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.vat)}</td>
                          </tr>
                    </tbody>
                    <br/>
                    <br/>
                    <tbody>
                          <tr key={0}>
                               <td key={1} className="text-right">Total amount excluded VAT</td>
                               <td key={2} className="text-right"></td>
                               <td key={3} className="text-right"></td>
                               <td key={4} className="text-right">{
                                 new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.totalexclude)
                                 }</td>
                          </tr>
                          <tr key={0}>
                               <td key={1} className="text-right"></td>
                               <td key={2} className="text-right"></td>
                               <td key={3} className="text-right"></td>
                               <td key={4} className="text-right">

                               </td>
                          </tr>
                          <tr key={0}>
                               <td key={1} className="text-right"></td>
                               <td key={2} className="text-right"></td>
                               <td key={3} className="text-right">
                                <Button color="primary" onClick={() => this.saveOrder()}>Entwurf speichern</Button>
                               </td>
                               <td key={4}>
                                  <Button color="primary" onClick={() => this.create()}>PDF erstellen</Button>
                               </td>
                          </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const firebaseToken = gql`
mutation createPdf($data: PdfInput!) {
  createPdf(data: $data) {
    token
  }
}
`;
const savePdf = gql`
mutation saveNewOrder($data: PdfInput!) {
  saveNewOrder(data: $data) {
    token
  }
}
`;
export default   compose(graphql(firebaseToken, { name: "createPdf" }), graphql(savePdf, { name: "savePdf" }), withApollo)(User);
