/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Notifications from "views/Notifications.jsx";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/TableList.jsx";
import Aufträge from "views/Aufträge.jsx";
import Entwürfe from "views/Entwürfe.jsx";
import Produktliste from "views/Produktliste.jsx";
import Maps from "views/Maps.jsx";
import Upgrade from "views/Upgrade.jsx";
import UserPage from "views/UserPage.jsx";
import ViewUserPage from "views/ViewUserPage.jsx";
import Details from "views/details.jsx";
import SignIn from "views/Signup.jsx"
var dashRoutes = [
  {
  
    path: "/admin",
    name: "Dashboard",
    icon: "business_chart-pie-36",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Kundenliste",
    icon: "design_bullet-list-67",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/user-page",
    name: "Produktliste",
    icon: "shopping_shop",
    component: Produktliste,
    layout: "/admin"
  },
  {
    path: "/icons",
    name: "Maske",
    icon: "ui-1_simple-add",
    component: UserPage,
    layout: "/admin"
  },
  {
    path: "/drafts",
    name: "Entwürfe",
    icon: "design-2_ruler-pencil",
    component: Entwürfe,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Aufträge",
    icon: "files_single-copy-04",
    component: Aufträge,
    layout: "/admin"
  },
  {
    path: "/archiv",
    name: "Archiv",
    icon: "location_bookmark",
    component: Entwürfe,
    layout: "/admin"
  },
  {
    pro: true,
    path: "/upgrade",
    name: "Logout",
    icon: "objects_spaceship",
    component: Upgrade,
    layout: "/admin"
  },
  {
    pro: true,
    path: "/login",
    name: "Login",
    icon: "objects_spaceship",
    component: SignIn,
    layout: "/"
  },
  {
    pro: true,
    path: "/details/:name",
    name: "Details",
    icon: "objects_spaceship",
    component: Details,
    layout: "/admin"
  },
  {
    pro: true,
    path: "/orderdetails/:name",
    name: "Details",
    icon: "objects_spaceship",
    component: ViewUserPage,
    layout: "/admin"
  }


];
export default dashRoutes;
