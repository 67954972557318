/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  CardTitle,
  Table,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import Switch from '@material-ui/core/Switch';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { theadCreate, tbody, breadBody, chickenbody } from "variables/general";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { compose } from "redux";
class ViewUserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: "",
      batchnumber: "",
      ordernumber: "",
      data: tbody,
      dataChicken: chickenbody,
      databread: breadBody,
      companys: [],
      selectedCompany: {id: "", name: "", street: "", city: "", plz: "", vat: ""},
      total: 0,
      vat: 0,
      totalexclude: 0,
      dropdownOpen: false,
      vealbody:[],
      chickenbody:[],
      checkedB: false,
      checkedC: false
    }
  };
  componentDidMount () {
    this.getDetails()
    console.log(this.props.location.state.detail)
    if (parseInt(this.props.location.state.detail.status) === 1) {
      this.setState({
        checkedB: true,
        vealbody: JSON.parse(this.props.location.state.detail.Vealbody[0]),
        chickenbody: JSON.parse(this.props.location.state.detail.chickenbody[0]),
        total: this.props.location.state.detail.total,
        vat: this.props.location.state.detail.vat,
        totalexclude: this.props.location.state.detail.totalexclude
      })
    } else if (parseInt(this.props.location.state.detail.status) === 2){

        this.setState({
          checkedB: true,
          checkedC: true,
          vealbody: JSON.parse(this.props.location.state.detail.Vealbody[0]),
          chickenbody: JSON.parse(this.props.location.state.detail.chickenbody[0]),
          total: this.props.location.state.detail.total,
          vat: this.props.location.state.detail.vat,
          totalexclude: this.props.location.state.detail.totalexclude
        })
    } else {
      this.setState({
        vealbody: JSON.parse(this.props.location.state.detail.Vealbody[0]),
        chickenbody: JSON.parse(this.props.location.state.detail.chickenbody[0]),
        total: this.props.location.state.detail.total,
        vat: this.props.location.state.detail.vat,
        totalexclude: this.props.location.state.detail.totalexclude
      })
    }


  }
  getDetails = () => {
    this.props.client
      .query({
        query: gql`
          query ($id: ID!){
            company(id: $id) {
              id
              name
              street
              city
              plz
              service
              total
              vat
            }
          }
        `,
        fetchPolicy: "no-cache",
        variables: {
          id: this.props.location.state.detail.company
        }
      })
      .then(async data => {
        console.log('Orders: ', data)
        this.setState({selectedCompany: data.data.company})
      })
  }
  fetch_feed = async () => {
    this.props.client
      .query({
        query: gql`
          query {
            companys {
              id
              name
              street
              city
              plz
              vat
            }
          }
        `
      })
      .then(async data => {
        console.log("Fetch Feed: ", data.data.companys);
        this.setState({ companys: data.data.companys });
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };
  updateValue = (value, data, key) => {
    let kg = value * parseFloat(data[key].kg)
    console.log(kg)
    let result = kg * parseFloat(data[key].data[2])
    console.log(result)
    data[key].data[0] = value
    data[key].data[1] = ""+kg + "kg"
    data[key].data[3] = result.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    let total = this.totalveal() + this.totalchicken() + this.totalbread()
    let vat = total * 0.03;
    let totalexclude = total - vat;
    this.setState({data: data, total: total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), vat: vat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), totalexclude: totalexclude.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})
  }

  totalveal = () => {
    let total = 0;
    for (var i = 0; i < this.state.data.length; i++) {
      total = total + parseFloat(this.state.data[i].data[3])
    }
    return total
  }
  totalchicken = () => {
    let total = 0;
    for (var i = 0; i < this.state.dataChicken.length; i++) {
      total = total + parseFloat(this.state.dataChicken[i].data[3])
    }
    return total
  }
  totalbread = () => {
    let total = 0;
    for (var i = 0; i < this.state.databread.length; i++) {
      total = total + parseFloat(this.state.databread[i].data[3])
    }
    return total
  }
  updateChickenValue = (value, data, key) => {
    let kg = value * parseFloat(data[key].kg)
    console.log(kg)
    let result = kg * parseFloat(data[key].data[2])
    console.log(result)
    data[key].data[0] = value
    data[key].data[1] = ""+kg + "kg"
    data[key].data[3] = result.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    let total = this.totalveal() + this.totalchicken() + this.totalbread()
    let vat = total * 0.03;
    let totalexclude = total - vat;
    this.setState({dataChicken: data,
      total: total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      vat: vat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      totalexclude: totalexclude.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    })
  }
  updateBreadValue = (value, data, key) => {
    let kg = value * parseFloat(data[key].data[2])
    console.log(kg)
    data[key].data[3] = kg.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    let total = this.totalveal() + this.totalchicken() + this.totalbread()
    let vat = total * 0.03;
    let totalexclude = total - vat;
    this.setState({databread: data, total: total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    , vat: vat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    , totalexclude: totalexclude.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})
  }
  toggle = () => {
    this.setState({dropdownOpen: !this.state.dropdownOpen})
  }
  handleChange = name => event => {
  this.setState({checkedB: !this.state.checkedB });
  let data = {
    id: this.props.location.state.detail.id,
    status: "1",
    ordernumber: this.props.location.state.detail.ordernumber
  }
  console.log(data)
  this.props.updateOrder({  variables: {
        data: data
      }})
    .then(async data => {
      console.log("Fetch: ", data);
    })
    .catch(async err => {
      console.log("Err: ", err);
    });
};
  handleChange2 = name => event => {
   this.setState({checkedC: !this.state.checkedC });
   let data = {
     id: this.props.location.state.detail.id,
     status: "2",
     ordernumber: this.props.location.state.detail.ordernumber
   }
   console.log(data)
   this.props.updateOrder({  variables: {
         data: data
       }})
     .then(async data => {
       console.log("Fetch: ", data);
     })
     .catch(async err => {
       console.log("Err: ", err);
     });
  };
  create = async () => {
    let {detail} = this.props.location.state
    this.setState({loading: true})
    let data = {
      date: this.state.orderdate,
      store: false,
      createdAt: new Date().toLocaleDateString(),
      company: detail.company,
      companyname: detail.companyname,
      companycity: detail.companycity,
      status: "0",
      invoice: detail.invoice,
      batchnumber: detail.batchnumber,
      ordernumber: detail.ordernumber,
      chickenbody: [JSON.stringify(this.state.chickenbody)],
      Vealbody: [JSON.stringify(this.state.vealbody)],
      Breadbody: [JSON.stringify(this.state.databread)],
      total: this.state.total,
      vat: this.state.vat,
      totalexclude: this.state.totalexclude
    }
    console.log(data)
    this.props.createPdf({  variables: {
          data: data
        }})
      .then(async data => {
        console.log("Fetch: ", data);
        setTimeout(() => {
          this.setState({loading: false})
          setTimeout(() => {
            window.open("https://api.docs-kbkint.com/download/" + data.data.createPdf.token, "_blank")
          }, 1000)
        }, 3500)
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };
  saveOrder = async () => {
    let {detail} = this.props.location.state
    this.setState({loading: true})
    let data = {
      date: this.state.orderdate,
      store: true,
      createdAt: new Date().toLocaleDateString(),
      company: detail.company,
      companyname: detail.companyname,
      companycity: detail.companycity,
      status: "0",
      invoice: detail.invoice,
      batchnumber: detail.batchnumber,
      ordernumber: detail.ordernumber,
      chickenbody: [JSON.stringify(this.state.chickenbody)],
      Vealbody: [JSON.stringify(this.state.vealbody)],
      Breadbody: [JSON.stringify(this.state.databread)],
      total: this.state.total,
      vat: this.state.vat,
      totalexclude: this.state.totalexclude
    }
    console.log(data)
    this.props.savePdf({  variables: {
          data: data
        }})
      .then(async data => {
        console.log("Fetch: ", data);
        setTimeout(() => {
          this.setState({loading: false})
        }, 3500)
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };
  download= () => {
    window.open("https://api.docs-kbkint.com/download/" + this.props.location.state.detail.batchnumber, "_blank")
  }
  date = () => {
    let now = new Date(this.props.location.state.detail.date)
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);

    var today = now.getFullYear()+"-"+(month)+"-"+(day) ;
    return today
  }
  deleteOrder = () => {
    let data = {
      ordernumber: this.props.location.state.detail.ordernumber
    }
    this.props.deletePdf({  variables: {
          data: data
        }})
      .then(async data => {
        console.log("Fetch: ", data);
        window.location.href="https://docs-kbkint.com/maps"
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  }
  render() {
    const { detail } = this.props.location.state
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Maske</h5>
                </CardHeader>
                <CardBody>
                <Row>
                  <Col md="6">
                    <Form>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Invoice Nummer</label>
                            <Input
                            onChange={(name) => {
                                this.setState({invoice: name.target.value})}}
                              placeholder="Invoice Nummer"
                              value={detail.invoice}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label>Batch Nummer</label>
                            <Input
                            onChange={(name) => {
                                this.setState({batchnumber: name.target.value})}}
                              placeholder="Batch Nummer"
                              value={detail.batchnumber}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Order Nummer</label>
                            <Input
                            onChange={(name) => {
                                this.setState({ordernumber: name.target.value})}}
                              placeholder="Order Nummer"
                              value={detail.ordernumber}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Order Date</label>
                            <Input
                              onChange={(name) => {
                                this.setState({orderdate: name.target.value})}}
                                defaultValue={this.date()}
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Auftrag Versendet</label>
                          <br />
                          <Switch
                            checked={this.state.checkedB}
                            onChange={this.handleChange('checkedB')}
                            value="checkedB"
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Auftrag abgeschlossen</label>
                          <br />
                          <Switch
                            checked={this.state.checkedC}
                            onChange={this.handleChange2('checkedC')}
                            value="checkedC"
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col md="6">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>{this.state.selectedCompany.name}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.selectedCompany.street}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>{this.state.selectedCompany.plz}, {this.state.selectedCompany.city}</td>

                      </tr>
                      <tr>
                        <td>VAT: {this.state.selectedCompany.vat}</td>

                      </tr>
                    </tbody>
                  </Table>
                  </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        {theadCreate.map((prop, key) => {
                          if (key === theadCreate.length - 1)
                            return (
                              <th key={key} className="text-right">
                                {prop}
                              </th>
                            );
                            if (key === theadCreate.length - 2)
                              return (
                                <th key={key} className="text-right">
                                  {prop}
                                </th>
                              );
                              if (key === theadCreate.length - 3)
                                return (
                                  <th key={key} className="text-right">
                                    {prop}
                                  </th>
                                );
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <thead className="text-primary">
                    <tr>
                      <th key={"Kebab"}>
                        Veal Kebab
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.vealbody.map((prop, key) => {
                        return (
                          <tr key={key}>
                            <td key={key} className="text-right">
                              <Row>
                                <Col className="pr-1" md="3">
                                <Input
                                  pattern="[0-9]*"
                                  type='number'
                                  onChange={(e) => this.updateValue(`${e.target.value}`, this.state.vealbody, key)}
                                  placeholder="0"
                                  value={prop.data[0]}
                                />
                                </Col>
                                <Col className="pr-1" md="3">
                                    X
                                </Col>
                                <Col className="pr-1" md="3">
                                    {prop.kg}kg
                                </Col>
                              </Row>
                            </td>
                            {prop.data.map((prop, key) => {
                              if (key === 0) {

                              } else {
                                if (key === theadCreate.length - 1|| key === theadCreate.length)
                                  return (
                                    <td key={key} className="text-right" >
                                    <strong>  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}</strong>
                                    </td>
                                  );
                                  if (key === theadCreate.length - 2)
                                    return (
                                      <td key={key} className="text-right">
                                       {
                                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)
                                      }
                                      </td>
                                    );
                                return <td key={key} className="text-right">{prop}</td>;
                              }

                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <thead className="text-primary">
                    <tr>
                      <th key={"Kebab"}>
                        Chicken Kebab
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.chickenbody.map((prop, key) => {
                        return (
                          <tr key={key}>
                          <td key={key} className="text-right">
                            <Row>
                              <Col className="pr-1" md="3">
                              <Input
                                type='number'
                                value={prop.data[0]}
                                onChange={(e) => this.updateChickenValue(`${e.target.value}`, this.state.chickenbody, key)}
                                placeholder="0"
                              />
                              </Col>
                              <Col className="pr-1" md="3">
                                  X
                              </Col>
                              <Col className="pr-1" md="3">
                                  {prop.kg}kg
                              </Col>
                            </Row>
                            </td>
                            {prop.data.map((prop, key) => {
                              if (key === 0) {

                              } else {
                                  if (key === theadCreate.length - 1 || key === theadCreate.length)
                                    return (
                                      <td key={key} className="text-right">
                                      <strong>  {
                                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}</strong>
                                      </td>
                                    );
                                  if (key === theadCreate.length - 2)
                                    return (
                                      <td key={key} className="text-right">
                                       {
                                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}
                                      </td>
                                    );
                                  return <td key={key} className="text-right">{prop}</td>;
                            }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <br/>
                    <br/>
                    <tbody>
                      {this.state.databread.map((prop, keyz) => {
                        return (
                          <tr key={keyz}>
                            {prop.data.map((prop, key) => {
                              if (key === 0)
                                return (
                                  <td key={key}>
                                    {prop}
                                  </td>
                                );
                                if (key === 1)
                                  return (
                                    <td key={key} className="text-right">
                                      <Row>
                                        <Col className="pr-1" md="3">
                                        <Input
                                          type='number'
                                          onChange={(e) => this.updateBreadValue(`${e.target.value}`, this.state.databread, keyz)}
                                          placeholder="0"
                                        />
                                        </Col>
                                        <Col className="pr-1" md="3">
                                            Boxes
                                        </Col>
                                        <Col className="pr-1" md="3">

                                        </Col>
                                      </Row>
                                      </td>
                                  );
                                  if (key === 3){
                                      return (<td key={key} className="text-right"><strong>{
                                        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}</strong></td>)} else {

                                        return (<td key={key} className="text-right">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prop)}</td>)
                                  }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <tbody>
                          <tr key={0}>
                               <td key={1} className="text-right"><strong>Total amount</strong></td>
                               <td key={2} className="text-right"></td>
                               <td key={3} className="text-right"></td>
                               <td key={4} className="text-right"><strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.total)}</strong></td>
                          </tr>
                          <tr key={0}>
                               <td key={1} className="text-right">{this.state.selectedCompany.service}% Service charge</td>
                               <td key={2} className="text-right"></td>
                               <td key={3} className="text-right"></td>
                               <td key={4} className="text-right">{
                                 new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.vat)}</td>
                          </tr>
                          <tr key={0}>
                               <td key={1} className="text-right">Total amount exclude VAT</td>
                               <td key={2} className="text-right"></td>
                               <td key={3} className="text-right"></td>
                               <td key={4} className="text-right">{
                                 new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.state.totalexclude)
                                 }</td>
                          </tr>
                    </tbody>
                    <br/>
                    <br/>

                  </Table>
                  <Table responsive>
                  <tbody width="100%">
                        <tr key={0}>
                             <td key={1} className="text-left">
                                  <Button color="danger" onClick={() => this.deleteOrder()}>Maske löschen</Button>
                             </td>
                             <td key={2} className="text-right" style={{width: '60%'}}>

                             </td>
                             <td key={3} className="text-right">
                                  <Button color="primary" onClick={() => this.download()}>PDF herunterladen</Button>
                             </td>
                             <td key={4}>
                                <Button color="primary" onClick={() => this.saveOrder()}>Entwurf speichern</Button>
                             </td>
                             <td key={5} >
                                <Button color="primary" onClick={() => this.create()}>PDF erstellen</Button>
                             </td>
                        </tr>
                  </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const deleteToken = gql`
mutation deletePdf($data: DeleteOrder!) {
  deletePdf(data: $data) {
    token
  }
}
`;
const firebaseToken = gql`
mutation createPdf($data: PdfInput!) {
  createPdf(data: $data) {
    token
  }
}
`;
const savePdf = gql`
mutation saveNewOrder($data: PdfInput!) {
  saveNewOrder(data: $data) {
    token
  }
}
`;
const updateOrder = gql`
mutation updateOrder($data: UpdateOrderInput!) {
  updateOrder(data: $data) {
    token
  }
}
`;
export default   compose(graphql(deleteToken, { name: "deletePdf" }),graphql(firebaseToken, { name: "createPdf" }), graphql(savePdf, { name: "savePdf" }),graphql(updateOrder, { name: "updateOrder" }), withApollo)(ViewUserPage);
