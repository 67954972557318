/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Route, Switch, Redirect } from "react-router-dom";

// core components
import DemoNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import Dashboard from "../views/Dashboard.jsx";
import routes from "routes.js";
import { graphql } from "react-apollo";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
var ps;

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      companys: [],
      orders: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      open: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      weight: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
  };

  mainPanel = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    this.fetch_companys()
    this.fetch_feed()

  }
  fetch_companys = async () => {
    this.props.client
      .query({
        query: gql`
          query {
            companys {
              id
              name
              street
              city
              plz
              vat
              service
            }
          }
        `
      })
      .then(async data => {
        console.log("Fetch Feed: ", data.data.companys);
        this.setState({ companys: data.data.companys });
      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };
  fetch_data_companys = async (id) => {
    if (parseInt(id) === 0) {
      this.fetch_feed()
    } else {
      if (id) {
        this.props.client
          .query({
            query: gql`
              query ($id: ID!){
                getCompanyByIdAnalytics(id: $id) {
                  count
                  total
                  month
                  open
                  weight
                }
              }
            `,
            variables: {
                    id: id
                  }
          })
          .then(async data => {
            console.log("Fetch Feed: ", data)
            let total = this.state.total.slice();
            total[data.data.getCompanyByIdAnalytics[0].month - 1] = parseInt(data.data.getCompanyByIdAnalytics[0].total)
            let orders = this.state.orders.slice();
            orders[data.data.getCompanyByIdAnalytics[0].month - 1] = parseInt(data.data.getCompanyByIdAnalytics[0].count)
            let open = this.state.open.slice();
            open[data.data.getCompanyByIdAnalytics[0].month - 1] = parseInt(data.data.getCompanyByIdAnalytics[0].open)
            let weight = this.state.weight.slice();
            weight[data.data.getCompanyByIdAnalytics[0].month - 1] = parseInt(data.data.getCompanyByIdAnalytics[0].weight)

            console.log("Fetch Feed: ", data);
            this.setState({orders: orders, total: total, open: open, weight:weight})
          })  .catch(async err => {
              console.log("Err: ", err);
            });
      }
    }



  };
  fetch_feed = async () => {
    this.props.client
      .query({
        query: gql`
          query {
            getDataAnalytics {
              count
              total
              month
              open
              weight
            }
          }
        `
      })
      .then(async data => {
        let total = this.state.total.slice();
        total[data.data.getDataAnalytics[0].month - 1] = parseInt(data.data.getDataAnalytics[0].total)
        let orders = this.state.orders.slice();
        orders[data.data.getDataAnalytics[0].month - 1] = parseInt(data.data.getDataAnalytics[0].count)
        let open = this.state.open.slice();
        open[data.data.getDataAnalytics[0].month - 1] = parseInt(data.data.getDataAnalytics[0].open)
        let weight = this.state.weight.slice();
        weight[data.data.getDataAnalytics[0].month - 1] = parseInt(data.data.getDataAnalytics[0].weight)

        console.log("Fetch Feed: ", data);
        this.setState({orders: orders, total: total, open: open, weight:weight})

      })
      .catch(async err => {
        console.log("Err: ", err);
      });
  };
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleColorClick = color => {
    this.setState({ backgroundColor: color });
  };
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          backgroundColor={this.state.backgroundColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar selectedCompany={(id) => this.fetch_data_companys(id)} companys={this.state.companys} {...this.props} />

            <Dashboard orders={this.state.orders} open={this.state.open} total={this.state.total} weight={this.state.weight}/>

          <Footer fluid />
        </div>

      </div>
    );
  }
}

export default withApollo(AdminLayout);
