/*!

=========================================================
* Now UI Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// ##############################
// // // tasks list for Tasks card in Dashboard view
// #############################

const tasks = [
  {
    checked: true,
    text: 'Sign contract for "What are conference organizers afraid of?"'
  },
  {
    checked: false,
    text: "Lines From Great Russian Literature? Or E-mails From My Boss?"
  },
  {
    checked: true,
    text:
      "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit"
  }
];

// ##############################
// // // table head data and table body data for Tables view
// #############################

const thead = ["Name", "Country", "City", "Salary"];
const aufträgehead = ["Ordernumber","Name", "Stadt", "Umsatz", "Ändern"];
const kundenlistehead = ["Name", "Stadt", "Umsatz", "Aktion"];
const produktliste = ["Name", "", "Single Price"];
const theadCreate = ["Article Name", "Quantity kg", "Single Price", "Total Price"];
const tbody = [
  {
    kg: "8",
    className: "table-success",
    data: [ 0,"0kg", 5.46, 0]
  },
  {
    kg: "15",
    className: "",
    data: [ 0,"0kg", 5.46, 0]
  },
  {
    kg: "30",
    className: "table-info",
    data: [0, "0kg", 5.46, 0]
  },
  {
    kg: "45",
    className: "",
    data: [0,"0kg", 5.46, 0]
  }

];
const aufträge = [
  {
    kg: "5",
    className: "table-success",
    data: [ "Zaddy's", "Deutschland", "30.000€", ""]
  },
  {
    kg: "5",
    className: "table-success",
    data: [ "Kplan", "Deutschland", "10.000€", ""]
  },
  {
    kg: "5",
    className: "table-success",
    data: [ "Mehmet Döner", "Deutschland", "50.000€", ""]
  },
  {
    kg: "5",
    className: "table-success",
    data: [ "Zaddy's", "England", "20.000€", ""]
  },

];
const kundenliste = [
  {
    kg: "5",
    className: "table-success",
    data: [ "Kplan",  "Westfälische Str. 49", "10711", "Berlin", "Deutschland", "30.000€", "10.00€"]
  },
  {
    kg: "5",
    className: "table-success",
    data: [ "Mehmet Döner", "Westfälische Str. 49", "10711", "Berlin", "Deutschland", "30.000€", "10.00€"]
  },
  {
    kg: "5",
    className: "table-success",
    data: [ "Zaddy's",  "Westfälische Str. 49", "10711", "Berlin", "Deutschland", "30.000€", "10.00€"]
  },
  {
    kg: "5",
    className: "table-success",
    data: [ "Zaddy's",  "Westfälische Str. 49", "10711", "Berlin", "Deutschland", "30.000€", "10.00€"]
  },

];
const chickenbody = [
  {
    kg: "8",
    className: "table-success",
    data: [0, "0kg", 4.21, 0]
  },
  {
    kg: "15",
    className: "",
    data: [ 0,"0kg", 4.21, 0]
  },
  {
    kg: "30",
    className: "table-info",
    data: [ 0,"0kg", 4.21, 0]
  },
  {
    kg: "45",
    className: "",
    data: [0,"0kg", 4.21, 0]
  }

];

const breadBody = [
  {
    data: ["Döner bread", 0, 18.49, 0]
  },
  {

    data: ["Lahmacun Bread", 0, 25.90, 0]
  },
  {
    data: ["Kcal brown bread",0, 13.40, 0]
  }

];
const produktlisteBody = [
  {
    data: ["Veal Kebab", "", 5.46,  ""]
  },
  {
    data: ["Chicken Kebab", "", 4.21, ""]
  },
  {
    data: ["Döner bread", "", 18.49, ""]
  },
  {

    data: ["Lahmacun Bread", "", 25.90, ""]
  },
  {
    data: ["Kcal brown bread","", 13.40,  ""]
  }

];

// tasks list for Tasks card in Dashboard view
// data for <thead> of table in TableList view
// data for <tbody> of table in TableList view
export { tasks, thead, tbody, theadCreate, breadBody, chickenbody, aufträge, aufträgehead, kundenliste, produktliste,kundenlistehead, produktlisteBody };
